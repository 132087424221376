<template>
  <v-theme-provider dark>
    <base-section
      id="social"
      class="accent text-center"
      space="56"
    >
      <base-icon class="mb-8">
        mdi-facebook
      </base-icon>

      <base-icon class="mb-8">
        mdi-instagram
      </base-icon>

      <base-section-heading
        color="transparent"
        title="Suivez-nous"
      >
        Abonnez-vous à nos page Facebook et Instagram pour suivre nos derniers projets
      </base-section-heading>
      <div class="py-4" />

      <v-btn
        color="primary"
        class="font-weight-bold"
        href="https://www.facebook.com/ironwoodgt"
        light
        min-width="168"
        target="_blank"
        x-large
      >

        Facebook
        <v-icon right>
          mdi-facebook
        </v-icon>
      </v-btn>
      &nbsp;
      <v-btn
        color="primary"
        class="font-weight-bold"
        href="https://www.instagram.com/ironwood_grandjean"
        light
        min-width="168"
        target="_blank"
        x-large
      >
        Instagram
        <v-icon right>
          mdi-instagram
        </v-icon>
      </v-btn>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNews',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
